import React from 'react'
import { Helmet } from "react-helmet"

const SEO = () => {
    return (
        <div>
            <Helmet>
                <title>SJVTools - Expert of Carbide Dies, Flat ground Thread Rolling Dies and Trimming dies in India</title>
                <meta name="description" content="SJVTools - Expert of Carbide Dies, Flat ground Thread Rolling Dies and Trimming dies in India" />
                <meta name="og:title" property="og:title" content="SJVTools - Expert of Carbide Dies, Flat ground Thread Rolling Dies and Trimming dies in India"></meta>
                <meta name="twitter:card" content="SJVTools - Expert of Carbide Dies, Flat ground Thread Rolling Dies and Trimming dies in India"></meta>
                <link rel="canonical" href="https://www.sjvtools.com/"></link>
            </Helmet>
        </div>
    )
}

export default SEO
